// This file can be replaced during build by using the `fileReplacements` array.
// `ng build -c prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { commonFeatureFlags, CommonFeatureFlags } from "../../../common/constants/CommonFeatureFlags";
import { commonEnvironment } from "./environment.common";

export const environment = {
    ...commonEnvironment,
    production: false,
    environment: "qa",
    redirectUrl: "https://jst.qa.findex.solutions",
    backendUrl: "https://fh8xqaist8.execute-api.ap-southeast-2.amazonaws.com/qa",
    locationsUrl: "https://qa.location.findex.dev",
    loggingLevel: commonEnvironment.loggingLevels.info,
    notification: {
        endpoint: "wss://kwbzpwtiwi.execute-api.ap-southeast-2.amazonaws.com/qa",
        channel: "qa",
    },
    sentry: {
        ...commonEnvironment.sentry,
        enabled: false,
    },
    documentServiceUrl: "https://go4iuvypag.execute-api.ap-southeast-2.amazonaws.com/qa",
    emailPreviewEndpoint: "https://fig333v3c8.execute-api.ap-southeast-2.amazonaws.com/qa/email/preview",
    azureAD: {
        ...commonEnvironment.azureAD,
        clientID: "7eb3893a-1d84-4189-862f-cdb3594a066b",
    },
    taskx: "https://qa.taskx.findex.dev",
    crm: "https://qa2-familyoffice.cs151.force.com/s/",
    googleAnalytics: {
        id: "UA-114939185-13",
    },
    hotjar: {
        id: "2488553",
    },
    constants: {
        DEBOUNCE_TIME_MS: 500,
        WS_RECONNECT_INTERVAL: 2000,
        SALESFORCE_LINK_PREFIX: "https://qa2-familyoffice.cs151.force.com/s/opportunity/",
        SALESFORCE_LINK_SUFFIX: "",
    },
    featureFlags: {
        [CommonFeatureFlags.YEARLESS_SCOPES]: commonFeatureFlags.qa.yearlessScopes,
    },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import "zone.js/plugins/zone-error";
